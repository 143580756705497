<template lang="pug">
settings-shell.user-settings
  template(#head)
    h1.title General Settings
  .box
    .setting
      h2.subtitle Reminders
      p.content
        | We'll send you an email every so often with a reminder to come and
        | update your net worth. You can choose how often you want to
        | receive these:
      .field(v-for="option in reminderOptions" :key="option.value")
        b-radio(v-model="remindAfter" name="remindAfter" :native-value="option.value" :disabled="savingReminderPreference")
          | {{option.name}}
      p.content(v-if="nextReminder")
        | Your next reminder will be sent on&nbsp;
        strong {{ nextReminder }}.
    .setting
      h2.subtitle Fractional Amounts
      p.content
        | You can enable or disable the tracking of cents and pennies in
        | traditional currencies. If you turn them off,
        | you won't be able to see or add cents, pennies or any other
        | fractional amounts with traditional currencies. Cryptocurrencies will be unaffected as
        | they rely heavily on fractional amounts.
      .field
        b-switch(v-model="useDecimals" name="useDecimals" :disabled="savingDecimalPreference")
          | Fractional amounts are&nbsp;
          strong {{ useDecimals ? "ON" : "OFF" }}
    .setting
      h2.subtitle Currency Precision
      p.content
        | You can also choose how many significant figures you care about.
        | This can be a great way to simplify the display of your holdings
        | by intelligently rounding each figure. It will not affect the way
        | that your holdings are tracked or your ability to enter in amounts
        | with greater precision.
      .columns.is-vcentered
        .column.is-narrow
          b-select(:value="maxSF" @input="saveMaxSF")
            option(v-for="option in SFOptions" :value="option.value") {{ option.name }}
        p.content.column
          | e.g
          code 123,456,789.12
          | will be shown as
          code {{ sfPreview }}
    .setting
      h2.subtitle Exchange Rate Display
      p.content
        | Enable this setting to view the exchange rate between your active
        | currency and the input currency while creating or editing an entry.
      .field
        b-switch(v-model="showExchangeRate" name="showExchangeRate" :disabled="savingExchangeRatePreference")
          | Exchange rate is&nbsp;
          strong {{ showExchangeRate ? "SHOWN" : "HIDDEN" }}
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { formatDate, formatNumber } from '@/misc/filters'

import SettingsShell from '@/components/SettingsShell.vue'
import reminderOptions from '@/misc/reminder-options'

const SFOptions = [...Array(20).keys()].map(x => ({
  name: `Display up to ${x + 1} significant figure${x ? 's' : ''}`,
  value: x + 1
}))

export default {
  name: 'SettingsView',
  components: {
    SettingsShell
  },
  data() {
    return {
      remindAfter: null,
      savingReminderPreference: false,
      reminderOptions,
      maxSF: 10,
      savingMaxSF: false,
      useDecimals: true,
      savingDecimalPreference: false,
      showExchangeRate: false,
      savingExchangeRatePreference: false,
      SFOptions
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    sfPreview() {
      const { maxSF, useDecimals } = this
      return formatNumber(123456789.12, {
        maxSF,
        minPrecision: useDecimals ? 2 : 0,
        maxPrecision: useDecimals ? 2 : 0
      })
    },
    nextReminder() {
      if (
        this.user &&
        this.user.remindAfter !== 'never' &&
        this.user.reminderDueAt
      ) {
        return formatDate(this.user.reminderDueAt, 'noyear')
      }
      return null
    }
  },
  watch: {
    'user.remindAfter': {
      handler(remindAfter) {
        this.remindAfter = remindAfter || 'month'
      },
      immediate: true
    },
    'user.preferences.maxSF': {
      handler(maxSF) {
        this.maxSF = maxSF
      },
      immediate: true
    },
    'user.preferences.useDecimals': {
      handler(useDecimals) {
        this.useDecimals = useDecimals
      },
      immediate: true
    },
    'user.preferences.showExchangeRate': {
      handler(showExchangeRate) {
        this.showExchangeRate = showExchangeRate
      },
      immediate: true
    },
    remindAfter(remindAfter) {
      if (remindAfter && remindAfter !== (this.user.remindAfter || 'month')) {
        this.saveRemindAfterPreference()
      }
    },
    useDecimals() {
      this.saveDecimalPreference()
    },
    showExchangeRate() {
      this.saveExchangeRatePreference()
    },
    maxSF(maxSF) {
      if (maxSF < 1 || maxSF !== Math.round(maxSF)) {
        this.maxSF = Math.round(Math.max(maxSF, 1))
      }
    }
  },
  methods: {
    ...mapActions('authentication', [
      'updateReminderPreference',
      'updatePreferences'
    ]),
    saveRemindAfterPreference() {
      this.savingReminderPreference = true
      this.updateReminderPreference(this.remindAfter)
        .then(() => {
          this.savingReminderPreference = false
          this.$buefy.toast.open('Saved reminder preference')
        })
        .catch(() => {
          this.savingReminderPreference = false
          this.$buefy.toast.open('Could not save reminder preference')
          this.remindAfter = this.user.remindAfter
        })
    },
    saveDecimalPreference() {
      const { useDecimals } = this
      this.savingDecimalPreference = true
      this.updatePreferences({ useDecimals })
        .then(() => {
          this.savingDecimalPreference = false
          this.$buefy.toast.open('Saved decimal preference')
        })
        .catch(() => {
          this.savingDecimalPreference = false
          this.$buefy.toast.open('Could not save decimal preference')
          this.useDecimals = this.user.preferences.useDecimals
        })
    },
    saveExchangeRatePreference() {
      const { showExchangeRate } = this
      this.savingExchangeRatePreference = true
      this.updatePreferences({ showExchangeRate })
        .then(() => {
          this.savingExchangeRatePreference = false
          this.$buefy.toast.open('Saved exchange rate preference')
        })
        .catch(() => {
          this.savingExchangeRatePreference = false
          this.$buefy.toast.open('Could not save exchange rate preference')
          this.showExchangeRate = this.user.preferences.showExchangeRate
        })
    },

    saveMaxSF(maxSF) {
      this.maxSF = maxSF
      this.savingMaxSF = true
      this.updatePreferences({ maxSF })
        .then(() => {
          this.savingMaxSF = false
          this.$buefy.toast.open('Saved precision reference')
        })
        .catch(() => {
          this.savingMaxSF = false
          this.$buefy.toast.open('Could not save precision preference')
          this.maxSF = this.user.preferences.maxSF
        })
    }
  },
  metaInfo: {
    title: 'General Settings'
  }
}
</script>

<style lang="sass" scoped>
.subtitle
  margin-top: 0
  margin-bottom: 0.5rem
.setting
  margin-bottom: 2.5rem
code
  margin: 0 0.35rem
</style>
