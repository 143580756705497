<template lang="pug">
settings-shell.user-settings
  template(#head)
    h1.title Manage Account
  .box
    .setting(v-if="canChangeEmail")
      h2.subtitle Change Email Address
      .field.has-addons
        .control.is-expanded
          b-input(v-model="email" type="email" @keydown.native.enter="changeEmail" :disabled="changingEmail")
        .control
          b-button.is-primary(:disabled="!user || email == user.email" @click="changeEmail" :loading="changingEmail") Change Email
    .setting(v-if="hasPassword")
      h2.subtitle Change Password
      .field.has-addons
        .control.is-expanded
          password-input(:label="null" v-model="password" @enter="changePassword" :disabled="changingPassword" :required="true" autocomplete="new-password")
        .control
          b-button.is-primary(:disabled="password.length < 8" @click="changePassword" :loading="changingPassword") Update Password
    .setting
      h2.subtitle Clear Entries
      p.content
        | You can completely remove all net worth entries you have created. These won't be
        | recoverable, so make sure you've made a backup first via our export function! Your
        | categories will be unaffected, so if you want to remove those as well head over to
        | your&nbsp;
        router-link(:to="{name: 'category_settings'}") Category Settings
        | &nbsp;afterwards.
      .field
        b-button.is-danger(@click="clearEntries" :loading="clearingEntries") Clear all Entries
    .setting
      h2.subtitle Delete Account
      p.content
        | If you no longer want to have an account with us you can delete it with the
        | button below. This will immediately remove all of your data from our systems
        | and we won't be able to get it back for you. Remember to save a copy of your data
        | first!
        br
        br
        | If there's something we can do better or an issue you're encountering,&nbsp;
        a(href="mailto:support@nutworth.com") we'd love to hear from you first.
      .field
        b-button.is-danger(@click="deleteAccount") Delete Account
</template>

<script>
import { mapState, mapActions } from 'vuex'
import firebase from 'firebase/app'

import SettingsShell from '@/components/SettingsShell.vue'
import PasswordInput from '@/components/PasswordInput.vue'

export default {
  components: {
    PasswordInput,
    SettingsShell
  },
  data() {
    return {
      email: '',
      password: '',
      changingEmail: false,
      changingPassword: false,
      clearingEntries: false
    }
  },
  computed: {
    ...mapState('authentication', ['user', 'canChangeEmail', 'hasPassword']),
    isPaidPlan() {
      return this.user.plan !== 'starter'
    },
    showChangePlan() {
      return (
        this.user.paymentState === 'needed' || this.user.plan !== 'lifetime'
      )
    },
    showCCUpdate() {
      if (this.user.plan === 'lifetime') return false
      return !!this.user.stripeId
    }
  },
  watch: {
    'user.email': {
      handler(email) {
        this.email = email
      },
      immediate: true
    }
  },
  mounted() {
    this.email = this.user.email
  },
  methods: {
    ...mapActions('authentication', [
      'cancelAccount',
      'changeEmailAddress',
      'reauthenticate'
    ]),
    ...mapActions('entries', ['clearAllEntries']),
    deleteAccount(event, retry = false) {
      let message =
        'Sign in again to proceed. This will permanently remove all your data. It cannot be undone!'
      if (retry) message = 'Invalid password. Please try again.'

      this.reauthenticate({
        title: 'Deleting Account',
        message,
        type: 'is-danger',
        hasIcon: true,
        confirmText: 'Delete Account'
      })
        .then(() => this.cancelAccount())
        .catch(error => {
          if (error.message === 'invalid-password')
            this.deleteAccount(event, true)
        })
    },
    clearEntries() {
      const message =
        'This will delete all of your net worth entries permanently. Proceed with caution and back up your data first!'
      this.$buefy.dialog.confirm({
        message,
        type: 'is-danger',
        onConfirm: () => {
          this.clearingEntries = true
          this.clearAllEntries()
            .then(() => {
              this.$buefy.toast.open('Removed all entries.')
              this.clearingEntries = false
            })
            .catch(error => {
              this.$buefy.dialog.alert({
                title: 'An error occurred!',
                message: error.message
              })
              this.clearingEntries = false
            })
        }
      })
    },
    changePassword(event, retry = false) {
      if (this.password.length < 8) {
        return
      }
      let message = 'Enter your current password to confirm.'
      if (retry) message = 'Invalid password. Please try again.'
      this.changingPassword = true
      this.reauthenticate({
        title: 'Changing Password',
        message,
        type: 'is-info',
        hasIcon: true
      })
        .then(() => firebase.auth().currentUser.updatePassword(this.password))
        .then(() => {
          this.changingPassword = false
          this.$buefy.toast.open('Password updated!')
        })
        .catch(error => {
          this.changingPassword = false
          if (error.message === 'invalid-password')
            this.changePassword(event, true)
        })
    },
    changeEmail(event, retry = false) {
      let message =
        'Enter your password to proceed. You will need to confirm your new address.'
      if (retry) message = 'Invalid password. Please try again.'
      this.changingEmail = true
      this.reauthenticate({
        title: 'Changing Email Address',
        message,
        type: 'is-info',
        hasIcon: true,
        confirmText: 'Confirm Change'
      })
        .then(() => {
          this.changeEmailAddress(this.email.trim())
            .then(() => {
              this.changingEmail = false
            })
            .catch(error => {
              this.changingEmail = false
              console.error(error.message)
              this.$buefy.toast.open('Failed to update email.')
            })
        })
        .catch(error => {
          this.changingEmail = false
          if (error.message === 'invalid-password')
            this.changeEmail(event, true)
        })
    }
  },
  metaInfo: {
    title: 'Manage Account'
  }
}
</script>

<style lang="sass" scoped>
.subtitle
  margin-top: 0
  margin-bottom: 0.5rem
.setting
  margin-bottom: 2.5rem
.subtitle + .field
  margin-top: 1rem
.has-addons
  max-width: 500px
  button
    width: 150px
@media screen and (max-width: 599px)
  .has-addons
    flex-direction: column
    button
      margin-top: 0.5rem
      border-radius: 4px !important
    ::v-deep input
      border-radius: 4px !important
</style>
